import {Tabs} from './tabs';

let tabs;

const initTabs = () => {
  // Scroll панели с кнопками

  const tabsBtn = document.querySelectorAll('[data-tabs="control"]');
  const tabsNav = document.querySelector('[data-tabs="controls"');

  tabsBtn.forEach((item) => {
    item.addEventListener('click', function () {
      let scrollLeftTab;
      const railsWidth = tabsNav.clientWidth;
      const railsWidthLeft = tabsNav.getBoundingClientRect().left;
      const tabWidth = item.offsetWidth;

      const tabLeftOffset = item.getBoundingClientRect().left;
      scrollLeftTab = Number(tabLeftOffset - (railsWidth / 2) + (tabWidth / 2) - railsWidthLeft);

      tabsNav.scrollBy({
        left: scrollLeftTab,
        behavior: 'smooth',
      });

    });
  });


  tabs = new Tabs();
  // Используйте в разработке экспортируемую переменную tabs, window сделан для бэкэнда
  window.tabs = tabs;
};

export {initTabs, tabs};
