export const initMenu = () => {
  const navBtn = document.querySelector('.menu__button');
  const nav = document.querySelector('.menu');
  const linkMenu = document.querySelectorAll('.menu__link[href^="#"]');

  const onMenuOpened = () => {
    nav.classList.add('menu--open');
    document.body.classList.add('scroll-lock');
  };

  const onMenuClosed = () => {
    nav.classList.remove('menu--open');
    document.body.classList.remove('scroll-lock');
  };

  const onMenuEsc = (evt) => {
    if (evt.keyCode === 27) {
      evt.preventDefault();
      nav.classList.remove('menu--open');
      document.body.classList.remove('scroll-lock');
    }
  };

  if (navBtn) {
    navBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (nav.classList.contains('menu--open')) {
        onMenuClosed();
        document.removeEventListener('keydown', onMenuEsc);
      } else {
        onMenuOpened();
        document.addEventListener('keydown', onMenuEsc);
      }
    });
  }

  if (linkMenu) {
    for (const anchor of linkMenu) {
      anchor.addEventListener('click', () => {
        if (nav.classList.contains('menu--open')) {
          onMenuClosed();
        }
      });
    }
  }

  // Фиксация меню при скролле
  // window.addEventListener('scroll', function (evt) {
  //   if (pageYOffset > 100) {
  //     nav.classList.add('menu--scroll');
  //   } else {
  //     nav.classList.remove('menu--scroll');
  //   }
  // })
};
