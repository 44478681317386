// import Swiper JS
// import Swiper from '../vendor/swiper-bundle.min';
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
//  import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

export const initSwiper = function () {
  const swiper = new Swiper('.product__slider .swiper', {
    modules: [Navigation, Pagination],
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.slider__button--next',
      prevEl: '.slider__button--prev',
    },
  });
};
